<template>
    <div id="competitions">

        <!--## Blocco tabella miei ultimi concorsi ##-->
        <div class="card">
            <div class="card-body">
                <div class="float-right mb-2 ml-2">
                    <button @click="$router.push({ name: 'AddCompetition' })" class="btn btn-primary btn-sm btn-rounded"><i class="mdi mdi-file-document-box-plus-outline"></i> {{$t('competitions.aggiungiNuovoConcorso')}}</button>
                </div>
                <h5 class="header-title mb-4 text-uppercase"><i class="mdi mdi-school-outline font-size-24 mr-1 align-middle"></i> {{$t('competitions.iMieiUltimiConcorsi')}}</h5>

                <template v-if="userCompetitions.length > 0">
                    <div class="table-responsive">
                        <b-table hover tbody-tr-class="cursorPointer" head-variant="light"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 :fields="fields"
                                 :items="userCompetitions"
                                 @row-clicked="setSelectedCompetitionDetail">
                            <!-- Head table -->
                                <template v-slot:head(concorso)="data">
                                    <div>{{$t('competitions.concorso')}}</div>
                                </template>
                                <template v-slot:head(anno)="data">
                                    <div class="text-center">{{$t('competitions.anno')}}</div>
                                </template>
                                <template v-slot:head(stato)="data">
                                    <div class="text-center">{{$t('competitions.stato')}}</div>
                                </template>
                                <template v-slot:head(domande)="data">
                                    <div class="text-center">{{$t('competitions.domande')}}</div>
                                </template>
                                <template v-slot:head(azioni)="data">
                                    <div class="text-center">{{$t('competitions.azioni')}}</div>
                                </template>
                            <!-- /Head table -->

                            <template v-slot:cell(ente)="data">
                                <span class="font-weight-semibold">{{data.value.ente}}</span>
                            </template>

                            <template v-slot:cell(anno)="data">
                                <div class="text-center">{{data.value}}</div>
                            </template>

                            <template v-slot:cell(stato)="data">
                                <div class="text-center">
                                    <div v-if="verifyIfQuestionsAreDownloaded(data.item.id_concorso) > 0" class="btn btn-primary btn-rounded waves-effect waves-light">{{getCompletion(data.item.id_concorso)}}%</div>
                                    <div v-else>-</div>
                                </div>
                            </template>

                            <template v-slot:cell(domande)="data">
                                <div class="text-center">
                                    <span style="color: #3051d3;" v-if="verifyIfQuestionsAreDownloaded(data.item.id_concorso) > 0">{{verifyIfQuestionsAreDownloaded(data.item.id_concorso)}}</span>
                                    <img src="../assets/images/icon-cloud.png" style="width: 39px; height: 39px;" v-else>
                                </div>
                            </template>

                            <template v-slot:cell(azioni)="data">
                                <div class="text-center">
                                    <div class="btn-group" role="group">
                                        <button type="button" @click="showModalConfirm(data.item.id_concorso)" class="btn btn-outline-danger" data-toggle="tooltip" data-placement="top" :title="$t('competitions.eliminaConcorso')">
                                            <i class="mdi mdi-trash-can"></i>
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <div class="mt-4">
                        <div class="pagination pagination-rounded justify-content-center mb-0">
                            <b-pagination v-model="currentPage" :per-page="perPage" pills :total-rows="userCompetitions.length"></b-pagination>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">{{$t('competitions.nonHaiAggiuntoConcorsi')}}</div>
                </template>

                <!-- Modal delete competition -->
                <div id="modalDelete" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalDeleteLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalDeleteLabel">{{$t('competitions.modale.delete.titolo')}}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div id="modal-body-delete" class="modal-body">
                                <h5 class="font-size-16">{{$t('competitions.modale.delete.sottotitolo')}}</h5>
                                <p>{{$t('competitions.modale.delete.doveVuoiCancellareIlConcorso')}}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary waves-effect waves-light" @click="deleteCompetition('local')" data-dismiss="modal">{{$t('competitions.modale.delete.locale')}}</button>
                                <button type="button" class="btn btn-primary waves-effect waves-light" @click="deleteCompetition('remote')" data-dismiss="modal">{{$t('competitions.modale.delete.remoto')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal delete competition -->

                <!-- Modal confirm -->
                <div id="modalConfirm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="myModalLabel">{{$t('competitions.modale.confirm.titolo')}}</h5>
                            </div>
                            <div id="modal-body" class="modal-body">
                                <h5 class="font-size-16">{{$t('competitions.modale.confirm.sottotitolo')}}</h5>
                                <p>{{$t('competitions.modale.confirm.seiSicuroDiVolerCancellareIlConcorso')}}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.chiudi')}}</button>
                                <button type="button" class="btn btn-primary waves-effect waves-light" @click="deleteCompetition('remote')" data-dismiss="modal">{{$t('generale.ok')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal confirm -->

                <!-- Modal competitions error -->
                <div id="modalCompetitionsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCompetitionsErrorLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalCompetitionsErrorLabel">{{$t('competitions.modale.errore.titolo')}}</h5>
                            </div>
                            <div id="modal-body-competitions-error" class="modal-body">
                                <p id="message-body-competitions-error"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal competitions error -->

                <!-- Modal competitions request error -->
                <div id="modalCompetitionsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCompetitionsRequestsErrorLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalCompetitionsRequestsErrorLabel">{{$t('competitions.modale.errore.titolo')}}</h5>
                            </div>
                            <div id="modal-body-competitions-requests-error" class="modal-body">
                                <p id="message-body-competitions-requests-error"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" id="button-ok-competitions" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: 'Home' })">{{$t('generale.annulla')}}</button>
                                <button type="button" id="button-retry-competitions" class="btn btn-primary waves-effect waves-light" @click="getConcorsiUtente()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal competitions request error -->

            </div>
        </div>
        <!--## Fine Blocco tabella miei ultimi concorsi ##-->

    </div>
</template>

<script>
    import 'bootstrap'
    import $ from 'jquery'
    import {mapActions, mapGetters} from 'vuex'
    import {MODULES} from "@/store/modules-enum";
    import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";
    import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
    import {BPagination, BTable} from 'bootstrap-vue'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import Worker from "../lib/ConcorsandoWasm/WrappedWorker";
    import Swal from "sweetalert2";
    import _ from 'lodash'
    import StatsManager from "../lib/ConcorsandoWasm/StatsManager";
    import {COMPETITION_MUTATIONS} from "@/store/competition/mutations-enum";
    import {routesEnums} from "@/helpers/enums";
    import {getNumberBetween} from "@/lib/utils";

    const worker = Worker.instance
    const statsManager = StatsManager.instance

    export default {
        name: "Competitions",
        components: {
            BTable,
            BPagination
        },
        data () {
            return {
                arrayOfIdCompetitionCompletion: [],
                perPage: 10,
                currentPage: 1,
                fields: [
                    {
                        key: 'ente',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'concorso',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'anno',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'Stato',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'Domande',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'Azioni',
                        tdClass: 'verticalCenter'
                    }
                ],
                metaCompetitions: [],
                idSelectedCompetition: {}
            }
        },
        computed: {
            ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.USER_COMPETITIONS, COMPETITION_GETTERS.SELECTED_COMPETITIONS])
        },
        mounted() {
            this.backToTop()
            this.getConcorsiUtente()
        },
        methods: {
            ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.DELETE_USER_COMPETITION, COMPETITION_ACTIONS.GET_USER_COMPETITIONS, COMPETITION_ACTIONS.SET_SELECTED_COMPETITION]),
            getCompletion (idCompetition) {
                const competition = _.find(this.arrayOfIdCompetitionCompletion, (c) => { return c.idConcorso === idCompetition && c.tot !== undefined })
                return competition !== undefined ? getNumberBetween(competition.tot, 0, 100) : 0;
            },
            async setCompletion () {
                this.arrayOfIdCompetitionCompletion = await Promise.all(this.userCompetitions
                    .map(userCompetition =>
                        statsManager.getCompletamento(userCompetition.id_concorso)
                            .then((result) => ({ idConcorso: userCompetition.id_concorso, tot: result.tot }))
                            .catch(() => {})
                    )
                )
            },
            deleteSelectedCompetition (idSelectedCompetition) {
                const selectedCompetitions = _.cloneDeep(this.selectedCompetitions)
                this.selectedCompetitions.forEach((e, i) => {
                    if (e.id_concorso === idSelectedCompetition) {
                        selectedCompetitions.splice(i, 1)
                    }
                })
                this.$store.commit(MODULES.COMPETITION + "/" + COMPETITION_MUTATIONS.SET_SELECTED_COMPETITIONS, selectedCompetitions)
            },
            deleteCompetition (type) {
                this.$emit('is-components-ready', 'competitions', false)
                const context = this
                if (type === 'local') {
                    worker.delConcorso(this.idSelectedCompetition)
                        .then(() => {
                            context.$emit('is-components-ready', 'competitions', true)
                            context.deleteSelectedCompetition(context.idSelectedCompetition)
                            context.getAllMetaCompetitions()
                            context.showModalDeleteSweetAlert()
                        })
                        .catch(() => {
                            context.$emit('is-components-ready', 'competitions', true)
                            $('#modalCompetitionsError').modal('show')
                            $('#message-body-competitions-error').html(context.$t('competitions.modale.errore.cancellazioneConcorso'))
                        })
                } else {
                    statsManager.resetStatsRemote(context.idSelectedCompetition, undefined, {remote: true})
                        .catch((err) => {
                            console.error(err)
                        })
                        .finally(() => {
                            this.deleteUserCompetition(context.idSelectedCompetition)
                                .then(() => {
                                    context.deleteSelectedCompetition(context.idSelectedCompetition)
                                    context.getConcorsiUtente(true)
                                })
                                .catch(() => {
                                    context.$emit('is-components-ready', 'competitions', true)
                                    $('#modalCompetitionsError').modal('show')
                                    $('#message-body-competitions-error').html(context.$t('competitions.modale.errore.cancellazioneConcorso'))
                                })
                        })
                }
            },
            getConcorsiUtente (isDeleteCompetitionRequest) {
                $('#modalCompetitionsRequestsError').modal('hide')
                const context = this
                this.$emit('is-components-ready', 'competitions', false)
                $('#button-retry-competitions').attr('disabled', 'disabled')
                this.getUserCompetitions()
                    .then(() => {
                        this.getAllMetaCompetitions()
                        return this.setCompletion()
                    })
                    .then(() => {
                        $('#modalCompetitionsError').modal('hide')
                        this.$emit('is-components-ready', 'competitions', true)
                        if (isDeleteCompetitionRequest !== undefined && isDeleteCompetitionRequest) {
                            context.showModalDeleteSweetAlert()
                        }
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'competitions', true)
                        $('#modalCompetitionsRequestsError').data('bs.modal', null)
                        $('#modalCompetitionsRequestsError').modal({backdrop: 'static', keyboard: false})
                        $('#button-retry-competitions').removeAttr('disabled')
                        $('#message-body-competitions-requests-error').html(context.$t('competitions.modale.errore.concorsiUtente'))
                    })
            },
            showModalConfirm (idConcorso) {
                this.idSelectedCompetition = idConcorso
                if (this.verifyIfQuestionsAreDownloaded(idConcorso) > 0) {
                    $('#modalDelete').modal('show')
                } else {
                    $('#modalConfirm').modal('show')
                }
            },
            getAllMetaCompetitions () {
                const context = this
                worker.getAllMeta()
                    .then((response) => {
                        context.metaCompetitions = response
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'competitions', true)
                        $('#modalCompetitionsError').modal({backdrop: 'static', keyboard: false})
                        $('#message-body-competitions-error').html(context.$t('competitions.modale.errore.getMetaConcorsi'))
                    })
            },
            verifyIfQuestionsAreDownloaded (idConcorso) {
                let n_questions = 0
                this.metaCompetitions.forEach(competition => {
                    if (competition.id_concorso === idConcorso) {
                        n_questions = competition.n_domande
                    }
                })
                return n_questions
            },
            setSelectedCompetitionDetail (row) {
                let context = this
                this.setSelectedCompetition(row.id_concorso)
                    .then(() => {
                        this.$router.push({ name: routesEnums.COMPETITION_DETAIL_WRAPPER })
                    })
                    .catch(() => {
                        $('#modalCompetitionsError').modal('show')
                        $('#message-body-competitions-error').html(context.$t('competitions.modale.errore.concorsoNonTrovato'))
                    })
            },
            showModalDeleteSweetAlert () {
                Swal.fire({
                    title: this.$t('competitions.sweetAlert.cancellaConcorso.title'),
                    text: this.$t('competitions.sweetAlert.cancellaConcorso.text'),
                    icon: 'success',
                    confirmButtonText: this.$t('generale.ok')
                })
            },
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            }
        }
    }
</script>